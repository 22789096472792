import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import NewsComp from "./NewsComp"

const NewsCompWidget = props => {
  const { classAdd } = props

  const { allStrapiNews } = useStaticQuery(graphql`
    query {
      allStrapiNews(limit: 3, sort: { fields: createdAt, order: DESC }) {
        nodes {
          title
          slug
          newsText {
            data {
              newsText
            }
          }
          imgSquare {
            url
          }
          createDate
          imgRectangle {
            url
          }
          imgSquare {
            url
          }
        }
      }
    }
  `)

  return <NewsComp data={allStrapiNews.nodes} classAdd={classAdd} />
}

export default NewsCompWidget
