import * as React from "react"
import ReactMarkdown from "react-markdown"

import "./newsDetail.scss"

import Layout from "../components/Layout"
import {graphql, Link} from "gatsby"
import HelpChoosing from "../components/offers/HelpChoosing"
import { AnimatePresence } from "framer-motion"
import FormPopup from "../components/popups/FormPopup"
import NewsCompWidget from "../components/NewsCompWidget"
import Title from "../components/Title"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import Img from "react-cool-img"
import { useState } from "react"
import { STRAPI_API } from "../consts/api"
import {SEO} from "../components/seo";
import {MENU_LINKS} from "../consts/MenuLinks";

const NewsDetail = ({ data, location }) => {
  const { allStrapiNews, strapiNewsPage } = data
  const newsData =
    allStrapiNews?.nodes?.length === 1 ? allStrapiNews?.nodes[0] : null

  const [formPopup, setFormPopup] = useState(false)

  return (
    <div>
      <SEO title={newsData?.titleDesc?.pageTitle} description={newsData?.titleDesc?.pageDescription}/>
      <Layout classAdd="newsDetailPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <div className="link-back">
            <Link to={MENU_LINKS.news}>Новости и акции</Link>
          </div>
            <div className="newsDetail__cont">
          {
            // NEWS TITLE
            newsData?.title && (
              <div className="news-header">
                <h1>{newsData?.title}</h1>
              </div>
            )
          }

          {
            // NEWS IMG
            newsData?.imgRectangle?.url && (
              <div className="newsDetail-img m60_40">
                <Img
                  placeholder={
                    STRAPI_API +
                    newsData?.imgRectangle?.url +
                    "?format=webp&width=40&grayscale=true"
                  }
                  src={
                    STRAPI_API + newsData?.imgRectangle?.url + "?format=webp"
                  }
                  alt="img"
                />
              </div>
            )
          }

          {
            // TEXT BLOCK
            newsData?.newsText?.data?.newsText && (
              <div className="m60_40 news-text">
                <ReactMarkdown children={newsData.newsText.data.newsText} />
              </div>
            )
          }

            </div>

          <Title value={[["dark", "Другие новости и акции"]]} classAdd="m140" />

          {
            // NEWS ADDITIONAL
            <NewsCompWidget classAdd={"m60_40 news--detail"} />
          }

          {
            // OFFER
            strapiNewsPage?.offer && (
              <HelpChoosing classAdd="m140" data={newsData.offer} />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export default NewsDetail

export const pageQuery = graphql`
  query NewsDetail($slug: String) {
    allStrapiNews(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        newsText {
          data {
            newsText
          }
        }
        imgSquare {
          url
        }
        createDate
        imgRectangle {
          url
        }
        imgSquare {
          url
        }
        titleDesc {
          pageTitle
          pageDescription
        }
      }
    }
    strapiNewsPage {
      offer {
        title
        subtitle
        button
      }
    }
  }
`
